.select {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  font-size: 0.875em;
  font-family: 'HelsinkiGrotesk', serif;
  line-height: 1.86;
  font-weight: bold;
  margin-bottom: 0.2em;
}

.select select {
  display: block;
  padding: 0.888em 0.72em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  font-size: 1.125em;
  border: 2px solid #cccccc;
  border-radius: 0;
  background-color: #ffffff;
  background-image: url('../../../../common/svg/ChevronDown.svg');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  appearance: none;
}

.select select::-ms-expand {
  display: none;
}
.select select:hover {
  border-color: #999898;
}
.select select:focus {
  border-color: #000000;
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #333333;
  outline: none;
}
.select select option {
  font-weight: normal;
}

.select select:disabled,
.select select[aria-disabled='true'] {
  color: graytext;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
}
.select select:disabled:hover,
.select select[aria-disabled='true'] {
  border-color: #b2b2b2;
}
