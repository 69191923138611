.stack {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: var(--spacing-m);
}

.addItemButton {
  margin-left: calc(-1 * var(--spacing-m));
  margin-bottom: var(--spacing-l);
  width: fit-content;
}

.additionalActionButton {
  margin-top: var(--spacing-xs);
}
.additionalActionButton:not(:first-child) {
  margin-left: var(--spacing-m);;
}
