.wrapper {
  margin: 2rem;
}

.wrapper h3 {
  font-size: 2.25rem;
  margin-top: 3rem;
  margin-bottom: 0.5rem;
}

.goBack {
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.label {
  width: 350px;
}

.labelTitle {
  font-weight: bold;
  font-size: 1.05rem;
  margin-bottom: 0.25rem;
}

.labelValue {
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
  white-space: pre-line;
}

.icon {
  margin-right: 15px;
}

.approverDescriptions {
  margin-bottom: 0;
}

.editButtons {
  margin-left: 0;
  display: flex;
  margin-top: 20px;
}

.button {
  background: #000000;
  color: #ffffff;
  padding: 10px 20px;
  margin-right: 20px;
}

@media (min-width: 550px) {
  .goBack {
    flex-direction: row;
    margin-top: 0;
  }

  .editButtons {
    margin-top: 0;
    margin-left: auto;
  }
}
