.actionRow {
  display: flex;
  margin-top: 15px;
  margin-bottom: 50px;
  flex-direction: column;
}

.textFieldFirstName,
.textFieldLastName {
  margin-left: 20px;
  margin-right: 20px;
}

.textFieldFirstName {
  order: 2;
}

.textFieldLastName {
  order: 3;
}

.create,
.search {
  background: #000000;
  color: #ffffff;
  height: 55px;
  min-width: 100px;
  margin-right: 20px;
}

.search {
  margin-left: 20px;
  margin-top: 25px;
  order: 3;
}

.create {
  margin-left: auto;
  order: 1;
  min-width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}

.searchResultText {
  margin: 20px 0;
  padding-left: 20px;
}

.dataGrid {
  padding: 0 20px;
}

@media only screen and (min-width: 775px) {
  .actionRow {
    flex-direction: row;
    align-items: flex-end;
  }

  .textFieldFirstName {
    margin-left: 0;
    order: 1;
  }

  .textFieldLastName {
    margin-left: 0;
    order: 2;
  }

  .search {
    margin-left: 0;
    order: 3;
  }

  .create {
    order: 4;
    margin-bottom: 0;
  }

  .dataGrid {
    padding: 0;
  }

  .searchResultText {
    padding-left: 0;
  }
}
